'use client';
import React, { useEffect, useState } from 'react';

import { colors } from '@/lib/theme/palette';

import { FilterValues } from '@/shared/components/MobileFilter/types.d';
import Icon from '@/shared/components/Icon';
import useQueryParams from '@/shared/hooks/useQueryParams.hook';

import { OutlinedInput, SxProps, Typography } from '@mui/material';

type Placeholders = {
  [key: string]: string;
};

const QUERY_FIELD_PLACEHOLDER: Placeholders = {
  companies: 'Company name',
  resources: 'Resource name',
};

type QueryFilterProps = {
  isMobile?: boolean;
  searchType: string;
  catalogMode?: string;
  filterValues?: FilterValues;
  hideTitle?: boolean;
  sx?: SxProps;
  type?: 'primary' | 'secondary';
  setFilterValues?: (filterValues: FilterValues) => void;
};

export default function QueryFilter({
  isMobile,
  searchType,
  filterValues,
  setFilterValues,
  hideTitle,
  type,
  sx,
}: QueryFilterProps) {
  const { queries, setQueries } = useQueryParams<{
    query: string;
    [key: string]: string;
  }>(
    {
      query: '',
    },
    { scroll: false },
  );

  const [value, setValue] = useState(queries.query);

  useEffect(() => {
    setValue(queries.query);
  }, [queries.query]);

  useEffect(() => {
    if (isMobile) {
      setValue(filterValues?.query || '');
    }
  }, [filterValues?.query]);

  const handleEnterRequest = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !isMobile) {
      setQueries({
        ...queries,
        page: '1',
        query: value,
      });
    }
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'secondary') {
      const value = e.target.value;

      setQueries({
        ...queries,
        page: '1',
        query: value,
      });
    }
  };

  const handleInputChange = (value: string) => {
    setValue(value);

    if (value.trim() === '' && !isMobile) {
      setQueries({
        ...queries,
        page: '1',
        query: value,
      });
    }
    if (isMobile && setFilterValues) {
      setFilterValues({
        categories: filterValues?.categories || [],
        companyType: filterValues?.companyType || [],
        resourceType: filterValues?.resourceType || [],
        keywords: filterValues?.keywords || [],
        query: value,
      });
    }
  };

  return (
    <>
      {!hideTitle && QUERY_FIELD_PLACEHOLDER[searchType] && (
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 700,
            marginBottom: '16px',
            ...(isMobile
              ? {
                  fontSize: '18px',
                }
              : {}),
          }}
        >
          {QUERY_FIELD_PLACEHOLDER[searchType]}
        </Typography>
      )}

      <OutlinedInput
        value={value}
        placeholder="Enter name"
        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange(e.target.value)
        }
        type="search"
        onKeyDown={handleEnterRequest}
        onChange={handleChangeValue}
        endAdornment={
          <Icon icon="search" width={16} height={16} color={colors.steel} />
        }
        sx={{
          borderRadius: '8px',
          height: '48px',
          width: '100%',
          '& > fieldset': {
            borderColor: colors.steel,
          },
          ...sx,
        }}
      />
    </>
  );
}
