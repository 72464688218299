// import { API_ENDPOINT } from '@/lib/utils';
import Cookie from 'js-cookie';
export interface GenericAbortSignal {
  readonly aborted: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onabort?: ((...args: any) => any) | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addEventListener?: (...args: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeEventListener?: (...args: any) => any;
}

type GetMethodParams<
  P = { [key: string]: object | string | number | boolean },
> = {
  params?: P;
  options?: RequestInit;
  signal?: GenericAbortSignal;
};

export class HTTPClient {
  private baseURL: string;
  private 'X-Access-Resource': string | undefined;
  private 'X-Access-Token': string | undefined;
  // private abortController: AbortController | null = null;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this['X-Access-Resource'] = Cookie.get('XAcessResource');
    this['X-Access-Token'] = Cookie.get('XAccessToken');
  }

  private generateOptions(options?: RequestInit): RequestInit {
    const settings: RequestInit = {
      method: 'GET',
      // cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Resource': this['X-Access-Resource'] || '',
        'X-Access-Token': this['X-Access-Token'] || '',
        ...options?.headers,
      },

      ...options,
    };
    return settings;
  }

  // private handleErrorResponse(error): Error {
  //   const err: HTTPErrorsResponse = {
  //     message: error.error,
  //     status: '500',
  //   };
  //   return new Error(err);
  // }
  public setXAccessToken(token: string): void {
    this['X-Access-Token'] = token;
    Cookie.set('XAccessToken', token, {
      expires: 60 * 60 * 24 * 365 * 1000,
    });
  }

  public getXAccessToken(): string | undefined {
    return this['X-Access-Token'];
  }

  public removeXAccessToken(): void {
    this['X-Access-Token'] = undefined;
    Cookie.remove('XAccessToken');
  }

  public setXAcessResource(token: string): void {
    this['X-Access-Resource'] = token;
    Cookie.set('XAcessResource', token, {
      expires: 60 * 60 * 24 * 365 * 1000,
    });
  }

  public getXAcessResource(): string | undefined {
    return this['X-Access-Resource'];
  }

  public removeXAcessResource(): void {
    this['X-Access-Resource'] = undefined;
    Cookie.remove('XAcessResource');
  }

  public async get<P, T>(
    url: string,
    payload?: GetMethodParams<P>,
    signal?: AbortSignal,
  ): Promise<T> {
    let fullURL: string = `${this.baseURL}${url}`;

    const params = (payload?.params as { [key: string]: any }) || {};

    Object.keys(params).forEach((key, index) => {
      if (params[key]) {
        fullURL += `${!fullURL.includes('?') ? '?' : '&'}${key}=${params[key]}`;
      }
    });

    // if (this.abortController) {
    //   this.abortController.abort();
    // }

    // this.abortController = new AbortController();

    const requestOptions = this.generateOptions(payload?.options);

    try {
      const response = await fetch(fullURL, {
        ...requestOptions,
        signal,
      });

      return await response.json();
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        throw new Error('Request was aborted');
      } else {
        throw new Error(
          error instanceof Error ? error.message : 'Unknown error',
        );
      }
    }
  }

  public async post<P, T>(
    url: string,
    data?: P,
    options?: RequestInit,
  ): Promise<T> {
    const requestOptions = this.generateOptions({ method: 'POST', ...options });

    try {
      const response = await fetch(`${this.baseURL}${url}`, {
        body: JSON.stringify(data),
        ...requestOptions,
      });

      if (!response.ok) {
        const errorData = await response.json();

        throw new Error(
          JSON.stringify(errorData) || 'Network response was not ok',
        );
      }

      return await response.json();
    } catch (error) {
      throw new Error(
        error instanceof Error ? error.message : 'Unknown error occurred',
      );
    }
  }

  public async put<P, T>(
    url: string,
    data?: P,
    options?: RequestInit,
  ): Promise<T> {
    const requestOptions = this.generateOptions({ method: 'PUT', ...options });

    try {
      const response = await fetch(`${this.baseURL}${url}`, {
        body: JSON.stringify(data),
        ...requestOptions,
      });

      return await response.json();
    } catch (error) {
      throw new Error('Network response was not ok');
    }
  }

  public async patch<P, T>(
    url: string,
    data?: P,
    options?: RequestInit,
  ): Promise<T> {
    const requestOptions = this.generateOptions({
      method: 'PATCH',
      ...options,
    });

    try {
      const response = await fetch(`${this.baseURL}${url}`, {
        body: JSON.stringify(data),
        ...requestOptions,
      });

      return await response.json();
    } catch (error) {
      throw new Error('Network response was not ok');
    }
  }

  public async delete<T>(url: string, options?: RequestInit): Promise<T> {
    const requestOptions = this.generateOptions({
      method: 'DELETE',
      ...options,
    });

    try {
      const response = await fetch(`${this.baseURL}${url}`, requestOptions);

      return await response.json();
    } catch (error) {
      throw new Error('Network response was not ok');
    }
  }
}

const httpClient = new HTTPClient(process.env.NEXT_PUBLIC_API_URL as string);

export default httpClient;
